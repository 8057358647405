
$red:  #64941c;
.bg-blue{
    background: $red;
    color: white;
}
.livescore{
    padding: 0 1rem;
    .flex{
        display: flex;
    }
    .livescore-header{
        // height: 1.9rem;
        padding-top: .4rem;
        
    }
    .livescore-button{
        padding: .5rem 1rem;
        border: 0;
        margin: 1rem .3rem;
        cursor: pointer;
    }
    .livescore-item{
            p{
                margin: .2rem !important;
            }
    }
    .my-1{
        margin-top: 2rem;
    }
    .px{
        padding: 0 1rem;
    }
    .mt{
        margin-top: .5rem;
    }
    .mt-2{
        margin-top: 1.5rem;
    }
    .ml-1{
        margin-left: .3rem;
    }
    .p-1{
        padding: .5rem .5rem;
    }
    .mx-2{
        margin: 0 1rem;
    }
    .livescore-count{
        background: rgb(132, 130, 130);
        border-radius: 10px;
        padding: .1rem .4rem;
    }
    .active{
        background: white;
        color: $red; 
    }
    .livescore-stage{
        background: white;
        color: green;
        font-size: .7rem;
        border-radius: 10px;
        padding: .1rem .9rem; 
        // margin: .5rem 0;
        display: block;
    }
    .livecsore-status{
        margin: .3rem .3rem;
        display: block;
        color:rgb(132, 130, 130);
    }
    .text-green{
        color: white;
    }
    .text-gray{
        color: rgb(132, 130, 130);
    }
    .text-red{
        color: #be2026;
    }
    .livescore-item-detail{
        padding: .2rem .5rem;
        padding-top: .2rem;
    }
    .livescore-item-detail:nth-child(odd) {
        background-color: #fffefe1c;
      }
    .livescore-item-detail-teams{
        width: 60%;
        margin-left: 10%;
       
        // justify-content: center;
        @media screen and (max-width: 400px){
        margin-left: 0%;
            
        }
        @media screen and (min-width: 401px ) and (max-width: 600px){
            margin-left: 4%;
                
            }
    }
   .result{
    font-weight: 300;
   }
}