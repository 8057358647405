$red:  #64941c;
.btn-send{
  border: 0px;
}

.cashbook{
    background: white;
    color: #4c4c4c;

    .t-header{
        display: flex;
        border-bottom:  .2px solid #4c4c4c;
       
        width: 100%;
        .ml{

          margin-left: -2rem;
        }
        .tab-item{
           margin-right: 2rem;
            padding: .5rem 1.5rem;
            cursor: pointer;
            border-width: 90%;
            font-size: .8rem;
            // background: $red;
            // color: white;
            margin-right: .5rem;
        }
        .active{
          padding: .5rem 1.5rem;
            border-top: 2px solid $red;
            background: $red;
            color: white;
            border-right: .2px solid #f4f4f4;
            // border-left: .2px solid #f4f4f4;
            border-bottom:  0px solid #f4f4f4;
        }
    }
    .tab-content{
      margin-top: 2rem;
        padding-bottom: 2rem;
    }
}

.btn-create{
    float: right;
    margin-bottom: 1rem;
    button{
        border: 0px;
        background: $red;
        color: white;
        padding: .5rem 1rem;
        cursor: pointer;
        margin-right: 1rem;
        // border-radius: 5px;
    }
}
.expense-form{
    margin-top: 1rem;
    .expense-input{
      margin: 1rem auto;
      width: 80%;
      select{
        width: 100%;
        padding: .5rem 1rem;
        border: 1px solid #d2d6de;
      }
      label{
        display: block;
        font-size: 1rem;
        margin-bottom: .5rem;
        color: #444242;
        text-align: left;
      }
      input{
        width: 93%;
        padding: .5rem 1rem;
        border: 1px solid #d2d6de;
      }
     
    }
  }
  .expense-btn{
    float: right;
    margin-right: 2rem;
    margin-bottom: 2rem;
    input{
      padding: .5rem 1rem;
      color: white;
      border: 0px;
      margin-right: 1rem;
    }
  }

  .btn-red{
    background: $red;
  }
  .btn-green{
    background: green;
  }
  .new-entry{
    padding-bottom: 2rem;
  }
.entry-field{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  label{
    color: #4c4c4c;
    font-size: .9rem;
  }
  input{
    width: 70%;
   
    padding: .5rem .5rem;
    border: 1px solid #d2d6de;
  }
}
.report{

  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 80%;
  margin: 2rem  auto;
  .report-field{
    width: 50%;
  label{
    color: #4c4c4c;
    font-size: .9rem;
    display: block;
  }
  input{
    width: 90%;
   display: block;
    padding: .5rem .5rem;
    border: 1px solid #d2d6de;
  }}
}
.ft-3{
  padding-top: 2rem;
}
.pl{
  padding-left: 1rem;
}
.funds-flex{
  .mt-2{
    margin-top: 1.5rem !important;
  }
  .mr-2{
    margin-right: 1rem;
  }
  .ml{
    margin-left: .5rem ;
  }
  display: flex;
   .one-half {
    // float: left;
    width: 48%;
    display: block;
  }
  .form-row {
    position: relative;
  }
  .form-label {
    font-weight: 700;
    margin: .5rem 0;
    font-size: 12px;
    line-height: 14px;
  }
  input:not([type=radio]):not([type=checkbox]) {
    height: 39px;
    border-radius: 3px;
    // background:red;
    // box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 10%), inset 0 0 0 1px #d7d9db;
    border: 1px solid #e4dcdc;
    width: 100%;
    padding-left: 15px;
    font-size: 13px;
  }
  textarea {
    border-radius: 3px;
    // box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 10%), inset 0 0 0 1px #d7d9db;
    border: 1px solid #e4dcdc;
    width: 100%;
    padding: 5px;
    font-size: 13px;
  }
  .btn {
    text-align: center;
    font-size: 13px;
    border-radius: 3px;
    height: 39px;
    display: block;
    cursor: pointer;
    padding: 0;
    text-transform: uppercase;
    color: #fff!important;
    font-weight: 700;
    font-family: Arial;
    margin-top: 2rem;;
    line-height: 16px;
    width: 100%;
    border: 1px solid transparent;
    background: linear-gradient(180deg,#2c944b,#2b8f49);
  }
  .form-input--stake {
    font-size: 12px;
    position: absolute;
    top: 19%;
    transform: translateY(-50%);
    right: 15px;
    color: rgba(18,18,18,.7);
  }
  .quickstake {
    display: flex;
  }
  .quickstake__item {
    margin-left: .5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.2%;
    font-size: 13px;
    text-align: center;
    background-color: #f0f2f5;
    border-radius: 3px;
    height: 44px;
    margin-top: 1rem;
  }
  .dgHdrStyle TH {
    padding: 0 2px;
    text-align: center;
  }
}

.mb-4{
  margin-bottom: 2rem;
}